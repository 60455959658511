import React from "react";
import "./firtsTab.css";
import bg_img from "../img/open_hours.jpg";
import { API_HOST } from "../config";

class firtsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open_info: [],
            date: null
        };
    }
    calcTime() {
        fetch('http://worldclockapi.com/api/json/cet/now').then(res=>{
            return res.json()
        }).then(data=>{
            this.setState({
                date: new Date(data.currentDateTime.slice(0, -6))
            })
        })
    }
    reloadData() {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", `${API_HOST}/api/v1/ringfan/open_hours`);
        xhr.onload = () => {
            this.setState({
                open_info: JSON.parse(xhr.responseText) || [],
            });
        };
        xhr.send();
    }

    componentDidMount() {
        this.calcTime();
        this.reloadData();
        setInterval(() => {
            this.calcTime();
            this.reloadData();
        }, 80000);
    }

    render() {
        let open_hours = [];
        if (typeof this.state.open_info === "object") {
            open_hours = this.state.open_info?.map?.((item, i) => {
                let status =
                    item.status === "opened" ? "status green" : "status close";
                
                return (
                    <div key={item.text} className="city_block">
                        <div className={status} />
                        <div className="name kievitot_regular">{item.text}</div>
                        <div className="open_date kievitot_bold">
                            <div className="perids_wrap">
                                {item.time.map((period) => (
                                    <span>{period.replace('-', '–')}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            });
        }

        let special_class =
            this.props.command === "show"
                ? "show"
                : this.props.command === "hide"
                ? "hide"
                : "start";
        if (this.props.command !== "show") {
            this.timer = setTimeout(() => {
                if (this.props.command === "show") {
                    this.props.changeFunc(1);
                }
            }, 20000);
        }
        if (this.props.command === "hide") {
            clearTimeout(this.timer);
        }
        return (
            <div className={special_class + " first_tab child"}>
                <img src={bg_img}></img>
                <div className={special_class + " image_block"}></div>
                <div className={special_class + " hidder"}></div>
                <div className="content_block">
                    <div className="paralax_block">
                        <p className="date notesstylenbr_brand">
                            {this.state.date && `${this.state.date.toLocaleString('de-DE', {day:'2-digit', month:'2-digit', year:'numeric', hour:'2-digit', minute:'2-digit'}).replace(',', ' /')}`}

                        </p>
                        <div className="title_containner">
                            <div className="location_title table_t kievitot_bold">
                                Location
                            </div>
                            <div className="open_hours_title table_t kievitot_bold">
                                open hours
                            </div>
                        </div>
                        {open_hours}
                        <div className="border" />
                    </div>
                </div>
            </div>
        );
    }
}
export default firtsTab;
