import React from "react";
import "./secondTab.css";
import Moment from "react-moment";
import bg_img from "../img/events.jpg";
import { API_HOST } from "../config";

class secondTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: "",
            rounds: "",
            currentStep: "",
        };
    }

    componentDidMount() {
        this.reloadLocation();
        setInterval(() => {
            this.reloadLocation();
        }, 20000);
    }

    reloadLocation() {
        fetch(`${API_HOST}/api/v1/ringfan/events`)
        .then((response) => response.json())
        .then((events) => {
            const todayDate = Date.now()
            const actualEvents = events.filter((event) => {
                const eventDate = Date.parse(event.date_start)
                return eventDate > todayDate
            })

            this.setState({
                events: actualEvents,
                rounds: Math.ceil(this.state.events.length / 3)
            })
        })
    }
    render() {
        let eventsArr = [];
        let special_class =
            this.props.command === "show"
                ? "show"
                : this.props.command === "hide"
                ? "hide"
                : "start";
        if (this.props.command === "show") {
            this.state.currentStep =
                typeof this.state.currentStep !== "number"
                    ? 0
                    : this.state.currentStep < this.state.rounds - 1
                    ? this.state.currentStep + 1
                    : 0;
        }
        if (typeof this.state.events === "object") {
            let borderArr =
                this.state.currentStep * 3 + 3 < this.state.events.length
                    ? this.state.currentStep * 3 + 3
                    : this.state.events.length;
            for (
                let index = this.state.currentStep * 3;
                index < borderArr;
                index++
            ) {
                const styleMain = {
                    backgroundImage:
                        typeof this.state.events[index].media.url === "string"
                            ? "url(" + this.state.events[index].media.url + ")"
                            : "",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    paddingRight: "30px"
                };
                eventsArr[index] = (
                    <div
                        key={Math.random()}
                        style={styleMain}
                        className="event_block"
                    >
                        <div className="filter"></div>
                        <p className="event_date notesstylenbr_brand">
                           
                            {this.state.events[index].date_start !== this.state.events[index].date_end ? (
                                <>
                                <Moment format= "DD.MM">
                                    {this.state.events[index].date_start}
                                </Moment>
                                <span>
                                     -<Moment format="DD.MM.YYYY">
                                        {this.state.events[index].date_end}
                                    </Moment>  
                                </span>
                                </>
                              
                            ) : (
                                <Moment format= "DD.MM.YYYY">
                                    {this.state.events[index].date_start}
                                </Moment> 
                            )}
                        </p>
                        <p className="event_place notesstylenbr_brand">
                            {this.state.events[index].text}
                        </p>
                    </div>
                );
            }
        }
        return (
            <div className={special_class + " second_tab child"}>
                <img src={bg_img}></img>
                <div className={special_class + " image_block"}></div>
                <div className={special_class + " hidder"}></div>
                <div className={special_class + " content_block"}>
                    <div className="paralax_block">
                        <p className="date notesstylenbr_brand" style={{marginBottom: "1rem"}}>UPCOMING EVENTS</p>
                        {eventsArr}
                    </div>
                </div>
            </div>
        );
    }
}
export default secondTab;
