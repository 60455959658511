import ReactDOM from "react-dom";
import React from "react";
import RaceApp from "./comp/app.jsx";
import "@babel/polyfill";

import "./main.css";
import "./fonts.css";

document.cookie = "same-site-cookie=foo; SameSite=Lax";

ReactDOM.render(<RaceApp />, document.getElementById("root"));
