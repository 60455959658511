import React from "react";
import img from "../img/logo.png";
import partnerLogo from "../img/label_logo.png";
import icons from "../icons/icons.svg";
import { API_HOST } from '../config'

class tabsSlot extends React.Component {
    constructor(props) {
        super(props);
        this.tabs = 0;
        this.move = this.move.bind(this);
        this.state = {
            biss_data: "1",
            weather: "1",
            icon: "",
            tempVal: "",
            partnerIndex: this.props.current_tab,
        };
    }

    componentDidMount() {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", `${API_HOST}/api/v1/ringfan/partners`)
        xhr.onload = () => {
            this.setState({
                biss_data: JSON.parse(xhr.responseText),
            });
        };
        xhr.send();
        let xhr2 = new XMLHttpRequest();
        xhr2.open("GET", `${API_HOST}/api/v1/ringfan/weather`)
        xhr2.onload = () => {
            this.setState({
                weather: JSON.parse(xhr2.responseText),
            });
            this.setState({
                tempVal: Math.ceil(this.state.weather.temp),
            });

            if (this.state.weather.length) {
                if ((this.state.weather[0].text = "clear sky")) {
                    this.setState({
                        icon: icons.sun_icon,
                    });
                } else if ((this.state.weather[0].text = "light rain")) {
                    this.setState({
                        icon: icons.Dainy_Rain,
                    });
                } else if ((this.state.weather[0].text = "overcast clouds")) {
                    this.setState({
                        icon: icons.Cloud,
                    });
                }
            }
        };
        xhr2.send();
    }

    componentDidUpdate(prevProps, prevState) {
        const currentIterate = this.props.current_tab;

        if (prevProps.current_tab !== currentIterate) {
            this.setState({
                partnerIndex: currentIterate,
            });
        }
    }
    make_arr(index_l, index_f) {
        let arr = [];
        for (let index = 0; index < 5; index++) {
            arr[index] = "tab_slot";
            if (index === index_f) {
                arr[index] = arr[index] + " active";
            }
            if (index === 0) {
                arr[index] = arr[index] + " first";
            }
        }
        return arr;
    }
    move(e) {
        let attrib = parseInt(e.target.getAttribute("pos"));
        this.props.changeFunc(attrib);
    }
    render() {
        let classes_arr = [
            "tab_slot first active",
            "tab_slot",
            "tab_slot",
            "tab_slot",
            "tab_slot",
        ];
        if (this.props.current_tab !== this.tabs) {
            classes_arr = this.make_arr(this.tabs, this.state.partnerIndex);
            this.tabs = this.state.partnerIndex;
        }
        let spons_el = "";
        let weather = "";
        let result = "";
        let partnersCount =
            this.state.partnerIndex >
            Object.keys(this.state.biss_data).length - 1
                ? 0
                : this.state.partnerIndex;

        if (typeof this.state.weather === "object") {
            const svgIcon = this.state.weather.icon;

            result = svgIcon.match(/'(.*?)'/);
        }

        if (typeof this.state.biss_data === "object") {
            spons_el = (
                <img src={this.state.biss_data[partnersCount].media.url}></img>
            );
        }
        /*if (typeof (this.state.weather) === 'object' && this.state.weather[0].media.hasOwnProperty('url')) {

    } */

        return (
            <div className="tabs_slot">
                <div className="logo_containner">
                    <img src={img} />
                </div>
                <div
                    className={classes_arr[0] + " kievitot_bold"}
                    pos="0"
                    onClick={this.move}
                >
                    <span pos="0">Open Hours</span>
                </div>
                <div
                    className={classes_arr[1] + " kievitot_bold"}
                    pos="1"
                    onClick={this.move}
                >
                    <span pos="1">Events</span>
                </div>
                <div
                    className={classes_arr[2] + " kievitot_bold"}
                    pos="2"
                    onClick={this.move}
                >
                    <span pos="2">EXPERIENCE</span>
                </div>
                <div
                    className={classes_arr[3] + " kievitot_bold"}
                    pos="3"
                    onClick={this.move}
                >
                    <span pos="3">TOURIST DRIVES</span>
                </div>

                <div className="weather_partners">
                    <a href="#" className="biss_data_block">
                        {spons_el}
                    </a>
                    <div className="weather_block">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                            <use xlinkHref={icons + result[1]} />
                        </svg>
                        <div className="weather_info">
                            <span className="title kievitot_bold">
                                {this.state.tempVal}
                            </span>
                            <span className="point kievitot_bold">0</span>
                            <span className="meausure kievitot_bold">C</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default tabsSlot;
