import Header from "./header.jsx";
import PagesBody from "./pagesBody.jsx";
import React from "react";

class app extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current_tab: 0,
        };
        this.current_tabs_length = 3;
        this.tick = this.tick.bind(this);
    }
    componentDidMount() {
        this.startCircle();
    }
    startCircle() {
        this.timer = setTimeout(() => {
            let future =
                this.state.current_tab === 3 ? 0 : this.state.current_tab + 1;
            this.tick(future);
        }, 20 * 1000);
    }
    tick(a) {
        clearTimeout(this.timer);
        this.setState(
            {
                current_tab: a,
            },
            this.startCircle
        );
    }
    render() {
        const current_tab = this.state.current_tab;
        return [
            <Header
                key="2"
                ontickFunck={this.tick}
                current_tab={current_tab}
            />,
            <PagesBody
                key="1"
                ontickFunck={this.tick}
                current_tab={current_tab}
            />,
        ];
    }
}
export default app;
