import React from 'react';


class contactInfor extends React.Component {
    constructor(props){
        super(props);
    }
    render() {
      return <div className ='contact_info'>
         <div className='phone kievitot_bold'><span>info & tickets</span><span style={{display: "flex"}}>0800 2083200 <div className='slash'>/</div> nuerburgring.de</span></div>
      </div>;
    }
  }
export default contactInfor;