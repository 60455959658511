import React from "react";
import "./header.css";
import ContactInfor from "./contactInfor.jsx";
import TabsSlot from "./tabsSlot.jsx";

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <header>
                <ContactInfor />
                <TabsSlot
                    changeFunc={this.props.ontickFunck}
                    current_tab={this.props.current_tab}
                />
            </header>
        );
    }
}
export default Header;
