import React, { useState } from "react";
import { API_HOST } from "../config";

import "./foursTab.css";
class foursTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cam_info: "",
        };
        this.keys = [Math.random(), Math.random()];
    }

    componentDidMount() {
        this.reloadLocation();
        setInterval(() => {
            this.reloadLocation();
        }, 20000);
    }
    reloadLocation() {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", `${API_HOST}/api/v1/ringfan/cams`);
        xhr.onload = () => {
            this.setState({
                cam_info: JSON.parse(xhr.responseText),
            });
        };
        xhr.send();
    }
    render() {
        let cam_info = [];

        if (typeof this.state.cam_info === "object") {
            cam_info = (
                <div className="cams_wrapper">
                    <CamBlock
                        title="NORDSCHLEIFE"
                        data={this.state.cam_info.nordschleife}
                    />
                    <CamBlock
                        title="GRAND-PRIX-STRECKE"
                        data={this.state.cam_info.gran_pri}
                    />
                </div>
            );
        }

        let special_class =
            this.props.command === "show"
                ? "show"
                : this.props.command === "hide"
                    ? "hide"
                    : "start";
        if (this.props.command === "show") {
            this.timer4 = setTimeout(() => {
                this.props.changeFunc(0);
            }, 20000);
        }
        if (this.props.command === "hide") {
            clearTimeout(this.timer4);
        }
        return (
            <div className={special_class + " fours_tab child"}>
                <p className="page_title notesstylenbr_brand">
                    WEBCAMS NÜRBURGRING
                </p>
                {cam_info}
            </div>
        );
    }
}

function CamBlock({ title, data }) {
    const statusClassName = data.status.active ? "opened" : "closed";
    const ringsPics = {
        "NORDSCHLEIFE": "https://live-image.panomax.com/cams/2527/recent_optimized.jpg",
        "GRAND-PRIX-STRECKE": "https://live-image.panomax.com/cams/2835/recent_reduced.jpg",
    }

    const [imagePosition, setImagePosition] = useState("50% 0");


    const handleMouse = (e) => {
        var left = e.target.getBoundingClientRect().left - document.body.scrollLeft;
        var width = parseFloat(getComputedStyle(e.target, null).width.replace("px", ""))
        var offset = e.pageX - left;
        var percentage = offset / width * 100;
        setImagePosition(percentage + '% 0');
        console.log(left);
    }
    console.log(title);
    return (
        <div className={"second cam_block"}>
            <div className={`status_block ${statusClassName}`}></div>
            <div className="named notesstylenbr_brand">&nbsp;{title}</div>
            <div className="block_img" onMouseMove={handleMouse} style={{ backgroundImage: `url(${ringsPics[title]})`, backgroundPosition: imagePosition }}>
            </div>
            <p className="subName kievitot_bold" style={{ fontSize: "1.5rem" }}>
                {data.status.title}
                <br />
                <span
                    className="subName kievitot_regular"
                    style={{ fontSize: "1.5rem" }}
                >
                    {data.status.description}
                </span>
            </p>
        </div>
    );
}

export default foursTab;
