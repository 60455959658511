import "./pages.css";
import React from "react";
import FirtsTab from "./firtsTab.jsx";
import SecondTab from "./secondTab.jsx";
import ThirdTab from "./thirdTab.jsx";
import FoursTab from "./foursTab.jsx";
class PagesBody extends React.Component {
    constructor(props) {
        super(props);
        this.comands = new Array(4);
    }

    render() {
        if (this.props.current_tab !== this.oldTab) {
            for (let index = 0; index < 4; index++) {
                if (index === this.props.current_tab) {
                    this.comands[index] = "show";
                } else if (index === this.oldTab) {
                    this.comands[index] = "hide";
                } else {
                    this.comands[index] = "";
                }
            }
            this.oldTab = this.props.current_tab;
        }

        return (
            <div className="page_containner">
                <FirtsTab
                    changeFunc={this.props.ontickFunck}
                    command={this.comands[0]}
                />
                <SecondTab
                    changeFunc={this.props.ontickFunck}
                    command={this.comands[1]}
                />
                <ThirdTab
                    changeFunc={this.props.ontickFunck}
                    command={this.comands[2]}
                />
                <FoursTab
                    changeFunc={this.props.ontickFunck}
                    command={this.comands[3]}
                />
            </div>
        );
    }
}
export default PagesBody;
