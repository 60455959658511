import React from "react";
import YouTube from "react-youtube";

class YoutubeParent extends React.Component {
    constructor(props) {
        super(props);
        this._endControll = this._endControll.bind(this);
    }
    render() {
        const opts = {
            height: "787.5",
            width: "1400",
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
                controls: 0,
                rel: 0,
                fs: 0,
            },
        };
        if (this.props.comand === "start") {
            this.feedBack = (
                <YouTube
                    videoId={this.props.link}
                    opts={opts}
                    onReady={this._onReady}
                    onEnd={this._endControll}
                />
            );
        } else {
            this.feedBack = [];
        }
        return <div>{this.feedBack}</div>;
    }
    _endControll(event) {
        this.props.next(3);
    }
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.playVideo();
    }
}
export default YoutubeParent;
