import "./thirdTab.css";
import React from "react";
import YouT from "./youtube.jsx";
import { API_HOST } from "../config";

class thirdTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            media: "",
            rounds: "",
            currentStep: 0,
            isBanner: false,
        };
        this.video = "";
        // this.timer3;
    }

    componentDidMount() {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", `${API_HOST}/api/v1/ringfan/faninfos`);
        xhr.onload = () => {
            this.setState({
                media: JSON.parse(xhr.responseText),
            });
            this.setState({
                rounds: this.state.media.length,
            });
        };

        xhr.send();
    }

    render() {
        let special_class =
            this.props.command === "show"
                ? "show"
                : this.props.command === "hide"
                ? "hide"
                : "start";
        let comand = "end";
        if (this.props.command === "show") {
            comand = "start";
            this.state.currentStep =
                typeof this.state.currentStep === 0
                    ? 0
                    : this.state.currentStep < this.state.rounds - 1
                    ? this.state.currentStep + 1
                    : 0;
        }
        if (this.props.command === "hide") {
            comand = "end";
        }
        let link = "";

        if (typeof this.state.media === "object") {
            // link = this.state.media[this.state.media.length - 1].media.url;
            if (
                this.state.media[this.state.currentStep].media.type === "video"
            ) {
                this.state.isBanner = false;
                link = this.state.media[this.state.currentStep].media.url;
                let video_id = link.split("/");
                link = video_id[video_id.length - 1];
            } else if (
                this.state.media[this.state.currentStep].media.type === "image"
            ) {
                this.state.isBanner = true;
                link = this.state.media[this.state.currentStep].media.url;
            }
        }

        return (
            <div className={special_class + " third_tab child"}>
                <div className="videoBlock">
                    {this.state.isBanner ? (
                        <div
                            style={{
                                backgroundImage: `url(${link})`,
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                flex: 1,
                            }}
                        />
                    ) : (
                        <YouT link={link} comand={comand} />
                    )}
                </div>
            </div>
        );
    }
}
export default thirdTab;
